import {
    getAlgoliaIndex,
    ObjectType,
    SortingField,
    SortingOrder,
    useAlgoliaResults,
} from '@jsmdg/algolia-helpers';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type Product } from '../../shared/types';
import { AlgoliaQueryId } from '../enums/AlgoliaQueryId';
import { type HeaderContext } from '../types';

type UseMostPopularProductsReturn = {
    loading: boolean;
    results: Product[];
    indexName: string;
    queryId?: string;
};

const useMostPopularProducts = (size = 3): UseMostPopularProductsReturn => {
    const { locale, tenant, algoliaConfig } = useFragmentContext<HeaderContext>();
    const indexName = getAlgoliaIndex({
        environment: algoliaConfig.environment,
        tenant,
        locale,
        sorting: {
            field: SortingField.SalesRank,
            order: SortingOrder.Desc,
        },
    });

    const { results, loading, queryId } = useAlgoliaResults<Product>({
        indexName,
        objectType: ObjectType.Product,
        search: {
            pagination: {
                offset: 0,
                size,
                currentPage: 1,
            },
        },
        id: AlgoliaQueryId.BestSellingProducts,
        options: {
            attributesToRetrieve: [
                'productId',
                'title',
                'url',
                'images',
                'price',
                'originalPrice',
                'rating',
                'closestLocation',
                'highlightedTitle',
                'classifications',
                'locations',
                'manufacturerSku',
                'productType',
                'trackingPath',
                'trackingName',
                'hasPriceRange',
            ],
            userToken: algoliaConfig.userToken,
        },
    });

    return { results, loading, indexName, queryId };
};

export { useMostPopularProducts };
